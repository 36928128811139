<template>
	<div class="introduce">
		<Header></Header>
		<div class="wrapper">
			<div class="benefit">
				<!-- 加入玉韦耶克终身养护计划的好处 -->
				<ul class="benefit-card">
					<li class="shadow">
						<img src="../../assets/img/benefit-icon01.png" />
						<p class="title">享受额外返利油卡</p>
					</li>
					<li class="shadow">
						<img src="../../assets/img/benefit-icon02.png" />
						<p class="title" style="font-size: 15px;">免费安全设备安装及使用:车道偏离预警(ADAS)、疲劳驾驶预警(DMS)</p>
					</li>
					<li class="shadow">
						<img src="../../assets/img/benefit-icon03.png" />
						<p class="title">优质的保养服务，保养到期提醒,车辆整备提醒</p>
					</li>
					<li class="shadow">
						<img src="../../assets/img/benefit-icon04.png" />
						<p class="title">发动机、变速箱、中桥、后桥部件终身延保。</p>
					</li>
					<li class="shadow">
						<img src="../../assets/img/benefit-icon05.png" />
						<p class="title">全天候服务热线</p>
					</li>
					<li class="shadow">
						<img src="../../assets/img/benefit-icon06.png" />
						<p class="title">省内免费拖车服务</p>
					</li>
				</ul>
			</div>
			<div class="join shadow">
				<div class="introduce-title">如何加入</div>
				<img src="../../assets/img/step.jpg" class="join-img" />
				<ul class="step">
					<li>
						<p class="title">第一步</p>
						<p>确认车辆是否符合加入条件。19年1月份以后出厂车辆，行驶里程小于30万公里。</p>
					</li>
					<li>
						<p class="title">第二步</p>
						<p>
						<p>进行签约，填写车辆详细信息。下一个保险周期起正式加入玉韦耶克终身养护计划。</p>
					</li>
					<li>
						<p class="title">第三步</p>
						<p>保险到期后在开通玉韦耶克终身养护计划的落户公司进行保险缴纳。</p>
					</li>
					<li>
						<p class="title">第四步</p>
						<p>建立玉韦耶克终身养护计划客户个人账号，并对签约车辆进行首检及整备。</p>
					</li>
				</ul>
			</div>
			<div class="service shadow">
				<div class="introduce-title">服务保障
					<br />咨询电话: 0533-6010118
				</div>
				<ul class="service-card">
					<li>
						<i class="iconfont">&#xe621;</i>
						<p class="title">太平洋保险公司承保</p>
						<i class="line"></i>
						<p>太平洋保险公司服务，赔付灵活及时宝达4s服务站一站式维修保养。</p>
					</li>
					<li>
						<i class="iconfont">&#xe610;</i>
						<p class="title">一键式查询维保记录</p>
						<i class="line"></i>
						<p>手机app、微信小程序，可自主监管维保信息。</p>
					</li>
					<li>
						<i class="iconfont">&#xe681;</i>
						<p class="title">纯正备品、品质保证</p>
						<i class="line"></i>
						<p>采用玉韦耶克纯正备品，价格透明，品质有保障，售后无担优。</p>
					</li>
					<li>
						<i class="iconfont">&#xe8a5;</i>
						<p class="title">无其他附加费用</p>
						<i class="line"></i>
						<p>明明白白消费，无额外手绫费服务费。</p>
					</li>
					<li>
						<i class="iconfont">&#xe662;</i>
						<p class="title">其他增值服务</p>
						<i class="line"></i>
						<p>月度油耗报表、驾驶习惯分析</p>
					</li>
				</ul>
			</div>

		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer
		},
		data() {
			return {

			}
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style lang="scss">
	@import "~@/assets/css/introduce.scss"
</style>
